<template>
  <div>
    <iframe
      frameborder="0"
      style="border: 0"
      referrerpolicy="no-referrer-when-downgrade"
      allowfullscreen
      data-cookieconsent="marketing"
      :data-cookieblock-src="url"
      class="cookieconsent-optin-marketing iframe"
    />
    <CookieNotice class="notice" :content-url="url" />
  </div>
</template>

<script lang="ts" setup>
const url =
  'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d305.35594817825427!2d21.0512226!3d52.246152!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecd003a26e82f%3A0x753c2516922bbc40!2sFabryka%20Czekolady%20E.Wedel!5e0!3m2!1spl!2spl!4v1721891801339!5m2!1spl!2spl'
</script>

<style lang="scss" scoped>
.notice {
  position: absolute;
  width: 100%;
}

.iframe {
  width: 100%;
  height: 100%;
}
</style>
