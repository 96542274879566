<template>
  <WidgetsPracticalInfoWidgetSectionContent
    :title="section.title"
    class="commute"
  >
    <div class="address" v-html="nbsp(section.address)" />
    <BaseButton
      color="tertiary"
      class="see-on-the-map lg-down"
      :text="t('see-on-the-map')"
      @click="isPopupActive = true"
    />
    <ClientOnly>
      <MapEmbed v-if="!isMobile" class="map lg-up" />
    </ClientOnly>
    <p
      v-if="section.description"
      class="description"
      v-html="nbsp(section.description)"
    />
    <CollapseGroup :id="`commute-${section.id}`" tag="ul">
      <Collapse
        v-for="{ title, content, id } in section.items"
        :id="`commute-item-${id}`"
        :key="id"
        tag="li"
      >
        <CollapseTitle class="item-title">
          <ChevronIcon class="item-title__icon" />
          <span class="item-title__text" v-html="nbsp(title)" />
        </CollapseTitle>
        <CollapseContent>
          <div class="wysiwyg-content" v-html="nbsp(content)" />
        </CollapseContent>
      </Collapse>
    </CollapseGroup>
    <p
      v-if="section.additional_info"
      class="additional-info"
      v-html="nbsp(section.additional_info)"
    />
    <ClientOnly>
      <BasePopup
        v-if="isMobile"
        :active="isPopupActive"
        @close="isPopupActive = false"
      >
        <MapEmbed class="map-mobile" />
      </BasePopup>
    </ClientOnly>
  </WidgetsPracticalInfoWidgetSectionContent>
</template>

<script lang="ts" setup>
import { useMediaQuery } from '@vueuse/core'

import ChevronIcon from '@/assets/icons/chevron-down.svg?component'
import type { PublicCommuneSubsection } from '~/service/__generated-api'

defineProps<{
  section: PublicCommuneSubsection
}>()

const { t } = useI18n()

const isMobile = useMediaQuery('(max-width: 1365px)')
const isPopupActive = ref(false)
</script>

<style lang="scss" scoped>
.address {
  @include font-size(
    (
      xs: 21px,
      md: 24px,
      lg: 23px,
      xl: 29px
    ),
    1.34
  );
  margin-bottom: rem(37px);
  letter-spacing: -0.025em;

  @include media-up(lg) {
    margin-bottom: rem(43px);
    line-height: 1.24;
  }
}

.see-on-the-map {
  max-width: rem(177px);
  margin-bottom: rem(50px);

  @include media-up(md) {
    margin-bottom: rem(36px);
  }

  .contrast-mode & :deep(.button) {
    color: $contrast-yellow;
    background-color: $contrast-black;
  }
}

.description {
  @include font-size(
    (
      xs: 16px,
      xl: 20px
    ),
    1.2
  );
  margin-bottom: rem(29px);

  @include media-up(lg) {
    margin-bottom: rem(17px);
    line-height: 1.25;
  }
}

.item-title {
  display: flex;
  gap: rem(12px);
  align-items: center;
  padding-block: rem(13px);

  @include media-up(lg) {
    padding-block: rem(7px);
  }
}

.item-title__text {
  @include font-size(
    (
      xs: 18px,
      lg: 16px,
      xl: 20px
    ),
    1.2
  );
}

.wysiwyg-content {
  @include font-size(
    (
      xs: 16px
    ),
    1.4375
  );
  padding-top: rem(23px);
  padding-bottom: rem(16px);
  padding-left: rem(8px);
  letter-spacing: -0.025em;

  @include media-up(lg) {
    padding-top: rem(12px);
    padding-bottom: rem(17px);
    padding-left: rem(16px);
  }

  :deep(p:not(:last-child)) {
    margin-bottom: rem(15px);

    @include media-up(lg) {
      margin-bottom: rem(3px);
    }
  }
}

@include collapse-chevron;

.item-title__icon {
  @include media-up(lg) {
    width: rem(8px);
  }
}

.additional-info {
  margin-top: rem(57px);

  @include media-up(md) {
    margin-top: rem(60px);
  }
}

.map {
  @include size(rem(297px));
  position: absolute;
  top: 0;
  right: 0;
}

.map-mobile {
  aspect-ratio: 1/1;
}

.commute {
  position: relative;
}
</style>
